import React from 'react';
import ReactLogo from '../assents/logo.png';

export default function MyNav({linkSobre, linkBolos, linkContato}) {
    return (
        <nav className="navbar navbar-expand-lg bg-white">
            <div className="container">
            <a className="navbar-brand mobileNav" href="/">
                        <img src={ReactLogo} alt="React Logo" />
                        </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        <a className="nav-link" href="/">Inicio</a>
                        <a className="nav-link" href={linkSobre}>Sobre nós</a>
                        <a className="navbar-brand desktopNav" href="/">
                        <img src={ReactLogo} alt="React Logo" />
                        </a>
                        <a className="nav-link" href={linkBolos}>Bolos</a>
                        <a className="nav-link" href={linkContato}>Contato</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}