import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import img from '../src/assents/whatsapp.png'
import './App.css';
import './Mobile.css';
import './Bolos.css'
import './Decoracao.css'


function App() {
  return (
    <BrowserRouter>
      <Routes />
      <a href="https://api.whatsapp.com/send?phone=5514981733303" target={'_blank'}><img src={img} width={'60px'} className={'btnWpp'}></img></a>
    </BrowserRouter>
  );
}

export default App;
