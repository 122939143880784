import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../src/pages/Home/index";
import Bolos from "./pages/Bolos";
import Decoracao from './pages/Decoracao/index'
export default () => {
    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/bolos">
                <Bolos />
            </Route>
            <Route exact path="/decoracao">
                <Decoracao/>
            </Route>
        </Switch>
    );
}