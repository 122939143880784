import Slider from "react-slick";
import topCard from "../../assents/topCard.png"

export default function Section6() {

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

    return (
        <section className='section6' id='bolos'>
            <div className='container'>
                <h2>Depoimentos</h2>
                <div className="hover">
                    <Slider {...settings}>
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title"><img src={topCard} alt="Avalaliação" width={35}></img></h5>
                                    <h6 className="card-subtitle m-3 fs-5">Obrigada! Vocês foram incriveis! ❤ Atendimento sensacional.</h6>
                                    <a href="#" className="card-link m-3 text-decoration-underline">Marina Rosa</a>
                                    <a href="/bolos" className="card-link m-3">Bolo Ruffles</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title"><img src={topCard} alt="Avalaliação" width={35}></img></h5>
                                    <h6 className="card-subtitle m-3 fs-5">Maravilhoso! O atendimento impecável! Obrigada.</h6>
                                    <a href="#" className="card-link m-3 text-decoration-underline">Marilia Souza</a>
                                    <a href="/bolos" className="card-link m-3">Bolo Nápoles</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title"><img src={topCard} alt="Avalaliação" width={35}></img></h5>
                                    <h6 className="card-subtitle m-3 fs-5">Tô apaixonada por cada detalhe, o bolo de vocês foi um dos que mais chamou atenção, ele entrou na decoração como se tivesse sido feito sobre medida pra nossa festa!</h6>
                                    <a href="#" className="card-link m-3 text-decoration-underline">Isabela Grecco</a>
                                    <a href="/bolos" className="card-link m-3">Bolo Delicado</a>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>

        </section>
    )
}