import React from "react";
import importedComponent from "react-imported-component";
import Slider from "react-slick";

const imageContext = require.context(
  "../../assents/bolos",
  false,
  /\.(png|jpg|jpeg|webp)$/
);
const importImages = imageContext.keys().map(imageContext);

const CardImg = importedComponent(() => import("../CardImg"));

export default function SectionBolos() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="container">
        <h1>Nossos Bolos</h1>
        <Slider {...settings}>
          {importImages.map((image, index) => (
            <div key={index}>
              <CardImg
                img={image}
                alt={"imagem"}
                text={image.replace("/static/media/", "").split(".")[0]}
                link={`https://api.whatsapp.com/send?phone=5514981733303&text=Olá, Gostaria de locar o Bolo ${
                  image.replace("/static/media/", "").split(".")[0]
                }`}
              />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}
