import Section2 from '../../components/HomePageComponents/Section2';
import Section3 from '../../components/HomePageComponents/section3';
import Section4 from '../../components/HomePageComponents/Section4';
import Section5 from '../../components/HomePageComponents/Section5';
import Section6 from '../../components/HomePageComponents/Section6';
import Header from '../../components/HomePageComponents/Header';
import Form from '../../components/Form';
import Rodape from '../../components/Rodape';
import MyNav from '../../components/Navbar';

export default function Home(){
    return(
        <div>
            <MyNav linkSobre={'#sobre'} linkBolos={'#bolos'} linkContato={'#contato'} />
            <Header />
            <Section2 id="sobre" />
            <Section3 id="bolos" />
            <Section4 />
            <Section5 />
            <Section6 />
            <Form title="Contato" id="contato" />
            <Rodape />
        </div>
    )
};