import foto2 from '../../assents/foto2.png'
import letering from '../../assents/letering.png'
export default function Section2() {
    return (
        <section className='section2'>
            <div className='container'>
                <div className="row align-items-center">
                    <div className="col-6">
                        <img src={letering} alt="O item essencial para seu evento" width={'90%'}></img>
                    </div>
                    <div className="col-6">
                        <img src={foto2} alt="O item essencial para seu evento" width={'100%'}></img>
                    </div>
                </div>
            </div>

        </section>
    )
}