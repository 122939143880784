import todosBolos from '../../assents/todosBolos.png'
import vejaDecoracao from '../../assents/vejaDecoracao.png'

export default function Section3() {
    return (
        <section className='section3' id='bolos'>
            <div className='container'>
                <div className='container3'>
                <div className='title2'>
                    <h1>Nossos Bolos</h1>
                    <p>Cada detalhe é muito importante para nós. Preenchemos <br /> sonhos com amor em cada bolo locado.</p>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <a href='/bolos'>
                            <img src={todosBolos} alt="O item essencial para seu evento" width={'90%'} />
                        </a>
                    </div>
                    <div className="col-md-6">
                        <a href='/decoracao'>
                            <img src={vejaDecoracao} alt="O item essencial para seu evento" width={'90%'} />
                        </a>
                    </div>
                </div>
                </div>
            </div>

        </section>
    )
}