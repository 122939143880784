
import Rodape from '../../components/Rodape';
import MyNav from '../../components/Navbar';
import Form from '../../components/Form';
import Header from '../../components/PageDecoracaoComponents/Header'
export default function pageDecoracao (){
    return(
        <>
        <MyNav linkBolos={'/'} linkContato={'/'} linkSobre={'/'} />
        <div className="headerDecoracao">
        <Header/>
        </div>
        <Form title="Agende um horário" />
        <Rodape />
        </>
    )
};