import capad from '../../assents/capad.png'
import capam from '../../assents/capam.png'

export default function MyHeader() {
    return (
        <header className='capa'>
            <img src={capad} className="img-fluid desktop" alt="..." />
            <img src={capam} className="img-fluid mobile" alt="..." />
        </header>
        )
}
