import grid from '../../assents/grid.png'

export default function HeaderDecoracao() {
    return (
        <>
            <div>
                <h1>Veja na decoração</h1>
            </div>
            <div className='container'>
             <img className='gridBolos' src={grid} alt="Decoração bolos cenicos" />
            </div>
        </>
    )
}; 