import imgBolos from '../../assents/bolosCatalogo.png'
import catalogo from '../../assents/catalogo.pdf';
export default function HeaderBolos() {
return (
    <>
    <div className="row align-items-center">
        <div className="col-md-6">
            <div className='title'>
          <h2>Baixe nosso Catálogo,</h2>
          <p className='subTitle'>e veja mais detalhes.</p>
          <a href={catalogo} target={'_blank'} className='baixar'>Baixar</a>
            </div>
        </div>
        <div className="col-md-6">
        <img src={imgBolos} alt="catalogo de bolos" width="100%"></img>
        </div>
    </div>
    </>
)
};