
export default function Section5() {
    return (
        <section className='section5' id='bolos'>
            <div className='container'>
                <div className="row">
                    <div className="col-md-4">
                        <h3>Missão</h3>
                        <p>Construímos sonhos através dos Bolos Cenográficos tornando-nos referência no mercado de eventos.</p>
                    </div>
                    <div className="col-md-4">
                        <h3>Visão</h3>
                        <p>Fazer com que os produtos da marca Atelier d'Amour Bolos Cenográficos, tornem-se conceitos, mantendo uma relação duradoura, com foco no crescimento.</p>
                    </div>
                    <div className="col-md-4">
                        <h3>Valores</h3>
                        <p>Vão de encontro a responsabilidade, a modernização, a inovação e principalmente ao bem estar de nossos clientes!</p>
                    </div>
                </div>
            </div>

        </section>
    )
}