
import Rodape from '../../components/Rodape';
import MyNav from '../../components/Navbar';
import Header from '../../components/PageBolosComponents/Header';
import Section from '../../components/PageBolosComponents/Section';
import Form from '../../components/Form';

export default function Bolos() {
    return (
        <>
            <div className='headerBolos'>
                <MyNav linkBolos={'/'} linkContato={'/'} linkSobre={'/'} />
                <div className='container'>
                    <Header />
                    <Section />
                    <Form title="Agende um horário" />
                </div>
            </div>
            <Rodape />
        </>
    )
}