import section4 from '../../assents/section4.jpg'
export default function Section4() {
    return (
        <section className='section4' id='sobre'>
            <div className='container'>
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <img src={section4} alt="O item essencial para seu evento" width={'90%'}></img>
                    </div>
                    <div className="col-md-6">
                        <h2>Sobre Nós</h2>
                        <p>De um sonho que se tornou realidade o Atelier d'Amour Bolos Cenográficos localizado na cidade de Bauru – SP surgiu através da inspiração do casal Stephanie e Victor em empreender no mundo dos eventos. Buscando sempre oferecer o que há de mais atual e moderno para nossos clientes.</p>
                    </div>
                </div>
            </div>

        </section>
    )
}