import relogio from '../assents/relogio.png'
import catalogo from '../assents/catalogo.pdf';
export default function Section7({ title }) {
    let checkbox = document.getElementById('flexCheckDefault');
    const link = document.getElementById('baixar');

    const handleDonwload = () => {
        if (checkbox.checked) {
            link.click();
        }
    };

    return (
        <section className='section7' id='contato'>
            <div className='container'>
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className='contacts'>
                            <h4>{title}</h4>
                            <span>
                                <img src={relogio} alt="O item essencial para seu evento" width={80}></img>
                                <h5>Atendimento com <br />horário agendado.</h5>
                            </span>
                            <a href="https://api.whatsapp.com/send/?phone=5514981733303&text&app_absent=0" target={'_blank'} className='agendar'>Agendar</a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <form action="https://formsubmit.co/goncalves.stephaniems@gmail.com" method="POST">
                            <div className='row'>
                                <div className='col'>
                                    <input type={'text'} className="form-control" name='name' placeholder='Nome' required></input>
                                </div>
                                <div className='col'>
                                    <input type={'email'} className="form-control" name='email' placeholder='E-mail' required></input>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <input type={'text'} className="form-control" name='phone' placeholder='Telefone' required></input>
                                </div>
                                <div className='col'>
                                    <input type={'date'} className="form-control" name='data' placeholder='Data do Evento'></input>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <textarea type={'text'} rows="2" name='message' className="form-control" placeholder='Digite sua mensagem aqui'></textarea>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <a href={catalogo} className='visually-hidden' id='baixar' target={'_blank'} />
                                        <label className="form-check-label">
                                            Baixe nosso catálogo
                                        </label>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <input type="hidden" name="_template" value="box" />
                                    <input type="hidden" name="_cc" value="contato@atelierdamour.com.br"></input>
                                    <input type="hidden" name="_autoresponse" value="A Atelier d'Amour agradece pelas informações, em breve entraremos em contato!"></input>
                                    <input type="hidden" name="_next" value="https://www.atelierdamour.com.br"></input>
                                    <input type="submit" className='enviar' value="Enviar" onClick={handleDonwload}></input>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </section>
    )
}